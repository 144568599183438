import React, {Component} from "react"
import DefaultLayout from "../bosom/components/layout/default/DefaultLayout";
import Pricing from "../views/pricing/Pricing";
import UnderConstructions from "../bosom/components/layout/under-constructions/UnderConstructions";

class PricingPage extends Component {
    constructor(props) {
        super(props);
        const previewUrl = this.props.pageContext.data.exercises["wrist-raises"];
        const sampleSvgPath = previewUrl + "/exercise.svg";

        this.state = {
            pageContext: this.props.pageContext,
            previewUrl : previewUrl,
            svgPath : sampleSvgPath
        };
    };

    render() {
        return (
            <DefaultLayout title={"Welcome"} context={this.props.pageContext}>
                <Pricing svgPath={this.state.svgPath} previewUrl={this.state.previewUrl}/>
                <UnderConstructions on={true}/>
            </DefaultLayout>
        )
    }
}

export default PricingPage;
