import React from "react"
import PricingOptions from "./options/PricingOptions";
import OneTwoBox from "../../bosom/components/layout/boxes/one-two-box/OneTwoBox";
import PricingSvg from "./assets/PricingSvg";

const Pricing = (props) => {

    return (
        <>
            <OneTwoBox svg={<PricingSvg/>}
                       title={"Subscription Options"}
                       description={"We offer a single subscription model which includes all features. Our pricing options are as follows:"}>
                <PricingOptions/>
            </OneTwoBox>

        </>
    )
}

export default Pricing;
